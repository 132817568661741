import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class SubmittedApplicationStore extends LocalModel {
    constructor() {
        super('submittedApplication', {
            namePlural: 'submittedApplications',
            namePluralReal: 'submittedApplications',
            sort: '-createdDate',
            limit: 100,
            api: {
                search: {
                    url: '/api/submittedapplications/',
                    params: {
                        limit: 15,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/submittedapplications/',
                    params: {},
                },
                save: {
                    url: '/api/submittedapplications/',
                    params: {},
                },
                delete: {
                    url: '/api/submittedapplications/',
                    params: {},
                },
            },
        });
    }

    @observable newSubmittedApplication = {};

    @observable submittedApplication = {};

    @observable submittedApplications = [];

    @observable customerApplications = [];

    @observable customerList = [];

    @observable tags = [];

    @observable municipalities = [];

    @observable counties = [];

    @observable categories = [];

    @observable areas = [];

    @observable fieldsOpts = [];

    @observable hideFields = [];

    @observable hideSections = [];

    @observable appOffer = {};

    @observable totalApplications = 0;

    @observable totalSubmittedApplications = 0;

    async loadIncomingApplications(query = { limit: 2000, offset: 0, isSubmitted: 1 }) {
        const response = await util.fetchApi(`/api/submittedapplications/customer/`, { method: 'GET' }, { ...query });
        if (response?.status === 200) {
            this.updateKeyValue('customerApplications', response.data);
            this.updateKeyValue('totalApplications', response.included?.totalApplications);
            this.updateKeyValue('totalSubmittedApplications', response.included?.totalSubmittedApplications);
        }
    }
    async getMessages(uuidv4, applicationOfferId){
        const response = await util.fetchApi(
            `/api/submittedapplications/portalo-messages/`,
            { method: 'GET' },
            {
                uuidv4,
                applicationOfferId
            }

        );
        return response.data;
    }
    async postMessage(applicationOfferId, message){
        const response = await util.fetchApi(
            `/api/submittedapplications/portalo-messages/`,
            { method: 'POST'},
            {message}

        );
        return response.data;
    }
    async fetchPortaloFile(applicationOfferId, filepath){
        const response = await util.fetchApi(
            `/api/submittedapplications/portalo-file/`,
            { method: 'GET'},
            {
                applicationOfferId,
                filepath
            }
        );
        return response.data;
    }

    async sendToPortalo(id, submittedApplication = this.submittedApplication) {
        const response = await util.fetchApi(`/api/submittedapplications/portalo/${id}`, { method: 'PATCH' }, {
            adminStatus: submittedApplication.adminStatus,
            adminComment: submittedApplication.adminComment,
            adminScore: submittedApplication.adminScore,
        });
        if (response?.status === 200) {
            console.log('Application sent to Portalo', response);
        }
    }
}

const store = new SubmittedApplicationStore();
export default store;
