import { h, Component, Fragment } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import linkstate from 'linkstate';
import { route } from 'preact-router';
import { Text, Localizer, withText } from 'preact-i18n';
import md5 from 'crypto-js/md5';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Select from 'react-select'

import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'

// import MarkdownIt from 'markdown-it';
import Markdown from 'preact-markdown';
import MdEditor, { Plugins } from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';

MdEditor.unuse(Plugins.Logger); // history

// Initialize a markdown parser
// const mdParser = new MarkdownIt(/* Markdown-it options */);

import Files from './files';
import Images from './images';
import DatePicker from '../calendar/datePicker';
import ClipboardCopy from '../gui/clipboardCopy';

import localUtil from '../../lib/util';

const getItemStyle = (isDragging, draggableStyle, width = '115px', height = '115px') => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // padding: grid * 2,
    margin: `0 8px 0 0`,

    // change background colour if dragging
    background: 'inherit', // isDragging ? 'lightgreen' : 'grey',
    // background: isDragging ? 'lightgreen' : 'grey',
    width,
    height,

    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
    // background: 'inherit', // isDraggingOver ? 'lightblue' : 'lightgrey',
    background: isDraggingOver ? 'inherit' : 'inherit',
    display: 'flex',
    // padding: grid,
    overflow: 'auto',
});

function escapeRegExp(string) {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function isValidDate(dateString) {
    // console.log('isValidDate', dateString);

    const date = new Date(dateString);
    // Check if the date is invalid
    if (isNaN(date)) {
        // console.log('isNaN(date)', isNaN(date));
        return false;
    }
    // Check if the year is before 1800
    if (date.getFullYear() < 1900) {
        // console.log('date.getFullYear() < 1900', date.getFullYear() < 1900);
        return false;
    }
    // If we reach here, the date is valid
    return true;
}

function isNumber(value) {
    // This regex matches a number that can start with an optional negative sign (-)
    // followed by one or more digits (\d+)
    // and an optional decimal part that can start with either a dot (.) or a comma (,)
    // followed by one or more digits (\d+)
    return /^-?\d+([.,]\d+)?$/.test(value);
}

function findAllKeys(list) {
    if (!Array.isArray(list) || list.length === 0) {
        return [];
    }

    const allKeys = list.reduce((keySet, item) => {
        // Add each key from the item to the set
        Object.keys(item).forEach(key => keySet.add(key));
        return keySet;
    }, new Set());

    return Array.from(allKeys); // Convert the set of keys back into an array
}

// Function to handle clicks outside the dropdown created by the company search input
function handleClickOutside(event) {
    const dropdown = document.getElementById('dropdown');
    const inputField = document.getElementById('orgNumberInput');

    if (dropdown && !dropdown.contains(event.target) && !inputField.contains(event.target)) {
        dropdown.innerHTML = ''; // Clear the dropdown
    }
}
// Add event listener to the document
document.addEventListener('click', handleClickOutside);
// Assuming the input field has an id of 'orgNumberInput'

//function to delay search input by x ms (750 found to be a good value)
// this is to not spam the server with requests and handle
// that the async results can come in at different times confusing the user
function debounce(func, wait) {
    let timeout;
    return function(...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}

@observer
class InputToggle extends Component {

    openSettings = e => {
        e.stopPropagation();
        e.preventDefault();
        const { openFieldSettings = () => {} } = this.props;
        const { section, field, type } = e.target.closest('i').dataset;
        // console.log({ section, field, type });
        openFieldSettings({ section, field, type });
    }

    render() {
        const { type, field, toggleFieldValues, toggleFieldFunction, extraDataset, section } = this.props;
        return (<>
            <div class='position-absolute d-flex flex-column align-items-center' style='right: -70px; top: 0px;'>
                <div class='custom-control custom-switch custom-switch-md d-flex justify-content-end'>
                    <input
                        type='checkbox'
                        class='inputForm custom-control-input'
                        id={`${extraDataset && extraDataset['data-id'] ? extraDataset['data-id'] : ''}${field}InputToggle`}
                        name={field}
                        value={(toggleFieldValues[field] === 1 || toggleFieldValues[field] === true || toggleFieldValues[field] === '1' || toggleFieldValues[field] === 'true') ? 0 : 1}
                        onInput={toggleFieldFunction}
                        checked={toggleFieldValues[field] == 1 ? 'checked' : ''}
                        autocomplete={'off'}
                    />
                    <label class='custom-control-label pl-2' for={`${extraDataset && extraDataset['data-id'] ? extraDataset['data-id'] : ''}${field}InputToggle`} />
                </div>
                <small class='text-muted'>Hidden</small>
            </div>
            <div class='d-flex justify-content-end position-absolute' style='right: -100px; top: 5px; font-size: 1.5em;'>
                <i
                    class='fa-duotone fa-gear text-muted'
                    onClick={this.openSettings}
                    data-section={section}
                    data-field={field}
                    data-type={type}
                />
                {/* <ClipboardCopy label={'Copy json'} text={`"${section}.${field}": { "help": ""${type === 'number' ? ', "min": 0, "max": 100' : ''} },`} /> */}
            </div>

        </>);
    }
}

@withText(props => ({
    mustBeNumberText: <Text id='input.must-be-number'>Must be a number</Text>,
    mustBeNumberBetweenText: <Text id='input.must-be-number-between'>Must be a number between</Text>,
}))
@observer
class Input extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageIdx: props.imageIdx || 0,
            imageElementScale: 1,
            imageElementMaxScale: 5,
            start: {},
            newObject: {},
            updateInProgress: false,
            validationMessage: {},
            imageIsLoading: {},
        };
        this.imageScrollerRef;
        this.updateTimer = {};
        this.inputRef = {};

        // binds the debounce function to the class
        this.handleSearchInput = debounce(this.handleSearchInput.bind(this), 750); // Adjust the wait time as needed
    }

    updateDuration = (name, value) => {
        const { field, updateField, object } = this.props;

        const hhmmss = util.secToHms(object[field]);
        const parts = hhmmss.split(':');
        let hh = parseInt(parts[0] || 0, 10);
        if (isNaN(hh)) {
            hh = 0;
        }
        let mi = parseInt(parts[1] || 0, 10);
        if (isNaN(mi)) {
            mi = 0;
        }
        let ss = parseInt(parts[2] || 0, 10);
        if (isNaN(ss)) {
            ss = 0;
        }
        if (name.match(/-hours/)) {
            if (isNaN(value)) {
                hh = 0;
            } else {
                hh = value;
            }
        }
        if (name.match(/-minutes/)) {
            if (isNaN(value)) {
                mi = 0;
            } else {
                mi = value;
            }
        }
        if (name.match(/-seconds/)) {
            if (isNaN(value)) {
                ss = 0;
            } else {
                ss = value;
            }
        }
        const combinedValue = (hh * 3600) + (mi * 60) + ss;
        updateField({ id: object.id, field, value: combinedValue });

    }

    onInputDuration = (e) => {
        const value = parseInt(e.target.value, 10);
        const name = e.target.name;
        this.updateDuration(name, value);
    }

    decDuration = (e) => {
        const { name } = e.target.closest('button').dataset;
        const { field, object } = this.props;

        const hhmmss = util.secToHms(object[field]);
        const parts = hhmmss.split(':');
        let hh = parseInt(parts[0] || 0, 10);
        if (isNaN(hh)) {
            hh = 0;
        }
        let mi = parseInt(parts[1] || 0, 10);
        if (isNaN(mi)) {
            mi = 0;
        }
        let ss = parseInt(parts[2] || 0, 10);
        if (isNaN(ss)) {
            ss = 0;
        }
        if (name.match(/-hours/)) {
            const value = hh - 1 < 0 ? 0 : hh - 1;
            this.updateDuration(name, value);
        }
        if (name.match(/-minutes/)) {
            const value = mi - 1 < 0 ? 0 : mi - 1;
            this.updateDuration(name, value);
        }
        if (name.match(/-seconds/)) {
            const value = ss - 1 < 0 ? 0 : ss - 1;
            this.updateDuration(name, value);
        }
    }

    incDuration = (e) => {
        const { name } = e.target.closest('button').dataset;
        const { field, object } = this.props;

        const hhmmss = util.secToHms(object[field]);
        const parts = hhmmss.split(':');
        let hh = parseInt(parts[0] || 0, 10);
        if (isNaN(hh)) {
            hh = 0;
        }
        let mi = parseInt(parts[1] || 0, 10);
        if (isNaN(mi)) {
            mi = 0;
        }
        let ss = parseInt(parts[2] || 0, 10);
        if (isNaN(ss)) {
            ss = 0;
        }
        if (name.match(/-hours/)) {
            const value = hh + 1 < 0 ? 0 : hh + 1;
            this.updateDuration(name, value);
        }
        if (name.match(/-minutes/)) {
            const value = mi + 1 < 0 ? 0 : mi + 1;
            this.updateDuration(name, value);
        }
        if (name.match(/-seconds/)) {
            const value = ss + 1 < 0 ? 0 : ss + 1;
            this.updateDuration(name, value);
        }

    }

    handleImageErrored = (e) => {
        const image = e.target;
        const { md5 } = image.dataset;

        const { imageIsLoading = {} } = this.state;
        imageIsLoading[md5] = 1;
        this.setState({ imageIsLoading });

        if (!image.dataset.retry) {
            image.dataset.retry = 0;
        }
        image.dataset.retry = parseInt(image.dataset.retry, 10) + 1;
        if (image.dataset.retry > 5) {
            return false;
        }

        image.onerror = null;
        setTimeout(() => {
            image.src += `?${new Date()}`;
        }, 1000);
    }

    handleImageLoaded = (e) => {
        const image = e.target;
        const { md5 } = image.dataset;

        const { imageIsLoading = {} } = this.state;
        delete imageIsLoading[md5];
        this.setState({ imageIsLoading });
    }

    removeMainImage = () => {
        const { object, field, updateField,  } = this.props;
        updateField({ id: object.id, field, value: {} });
    }

    setDateToNow = () => {
        const { field, updateField, object } = this.props;
        const value = util.isoDate(undefined, false, false, true);
        // updateFieldValue = ({ id, field, value, datatype, elementtype, type, isnew = false, skipTimer = false }) => {
        updateField({ id: object.id, field, value });
    }

    setTimeToNow = () => {
        const { field, updateField, object } = this.props;
        const value = util.isoTime();
        // updateFieldValue = ({ id, field, value, datatype, elementtype, type, isnew = false, skipTimer = false }) => {
        updateField({ id: object.id, field, value });
    }

    setValue = (e) => {
        const value = e.target.parentElement.dataset.value || e.target.dataset.value;
        if (value) {
            const { field, updateField, object } = this.props;
            // updateFieldValue = ({ id, field, value, datatype, elementtype, type, isnew = false, skipTimer = false }) => {
            updateField({ id: object.id, field, value });
        }
    }

    setRightValue = () => {
        const { field, updateField, object, rightValueReal } = this.props;
        updateField({ id: object.id, field, value: rightValueReal });
    }

    addImages = async obj => {
        const { holdingStore, object, objectName, callback = () => {}, isAdmin, field = 'images' } = this.props;
        const localValue = object[field] ? [...object[field]] : [];
        // console.log({ isAdmin, localValue, id: object.id, name: field, value: obj.value })
        if (object.id) {
            if (isAdmin) {
                await holdingStore.adminUpdate(object.id, { [field]: obj.value }, objectName);
            } else {
                // console.log('doAdminUpdate !isAdmin', { id: idAsInt, name, value });
                await holdingStore.saveField(object.id, field, obj.value);
            }
            holdingStore.updateField(object.id, field, [...localValue, ...obj.value]);
        }
        if (objectName) {
            holdingStore.updateObjectKeyValue(objectName, field, [...localValue, ...obj.value]);
        }
        callback();
	}

    addFiles = async obj => {
        const { holdingStore, object, objectName, isAdmin, field = 'files' } = this.props;
        const localValue = object[field] ? [...object[field]] : [];
        if (object.id) {
            if (isAdmin) {
                await holdingStore.adminUpdate(object.id, { [field]: obj.value }, objectName);
            } else {
                // console.log('doAdminUpdate !isAdmin', { id: idAsInt, name, value });
                await holdingStore.saveField(object.id, field, obj.value);
            }
            holdingStore.updateField(object.id, field, [...localValue, ...obj.value]);
        }
        if (objectName) {
            holdingStore.updateObjectKeyValue(objectName, field, [...localValue, ...obj.value]);
        }
	}

    addArrayElement = async (newObject) => {
        const { holdingStore, object, field, objectName, isAdmin } = this.props;
        const localValue = object[field] ? [...object[field]] : [];
        const value = newObject[field];
        if (object.id) {
            if (isAdmin) {
                await holdingStore.adminUpdate(object.id, { [field]: value }, objectName);
            } else {
                // console.log('doAdminUpdate !isAdmin', { id: idAsInt, name, value });
                await holdingStore.saveField(object.id, field, value);
            }
            holdingStore.updateField(object.id, field, [...localValue, value]);
        }
        if (objectName) {
            holdingStore.updateObjectKeyValue(objectName, field, [...localValue, value]);
        }
    }

    addArrayObject = async (newObject) => {
        const { holdingStore, object, field, objectName, isAdmin } = this.props;
        const localValue = object[field] ? [...object[field]] : [];
        // const keys = Object.keys(newObject);
        // keys.forEach(key => {
        //     // Check if it is string and trim whitespace
        //     if (typeof newObject[key] === 'string') {
        //         newObject[key] = newObject[key].trim();
        //     }
        // });
        const value = {
            ...newObject,
            md5: md5(JSON.stringify(newObject)).toString(),
            date: new Date(),
        };
        if (object.id) {
            if (isAdmin) {
                await holdingStore.adminUpdate(object.id, { [field]: value }, objectName);
            } else {
                // console.log('doAdminUpdate !isAdmin', { id: idAsInt, name, value });
                await holdingStore.saveField(object.id, field, value);
            }
            holdingStore.updateField(object.id, field, [...localValue, value]);
        }
        if (objectName) {
            holdingStore.updateObjectKeyValue(objectName, field, [...localValue, value]);
        }
	}

    openAddArrayObject  = () => {
        const {
            drawerLevel = 1,
            datalist = [],
            datalistHelp = [],
            datalistValues = [],
            object,
            field,
            fieldObject,
            type,
            min,
            maxValue,
            step,
            elementtype,
            holdingStore,
            drawerSize = 'small',
            drawerName = 'addArrayObject',
            drawerRight = false,
            isAdmin,
        } = this.props;
        const { appState } = this.props.stores;
        const { drawerHeightSmall, drawerHeightMedium } = appState;
        let drawerHeight = drawerHeightSmall;
        switch (true) {
            case /small/.test(drawerSize):
                drawerHeight = drawerHeightSmall;
                break;
            case /medium/.test(drawerSize):
                drawerHeight = drawerHeightMedium;
                break;
            case /drawerHeight.+/.test(drawerSize):
                drawerHeight = appState[drawerSize];
                break;
            default:
                drawerHeight = drawerHeightSmall;
                break;
        }

        if (drawerRight) {
            appState.openDrawerRight(drawerName, {
                height: drawerHeight,
                datalist,
                datalistHelp,
                datalistValues,
                object,
                field,
                fieldObject,
                type,
                min,
                maxValue,
                step,
                elementtype,
                holdingStore,
                isAdmin,
                after: this.addArrayObject,
                // showCloseButton: false,
                // showBackButton: true,
                // backButtonText: 'Back',
            }, drawerLevel + 1);
        } else {
            appState.openDrawer(drawerName, {
                height: drawerHeight,
                datalist,
                datalistHelp,
                datalistValues,
                object,
                field,
                fieldObject,
                type,
                min,
                maxValue,
                step,
                elementtype,
                holdingStore,
                isAdmin,
                after: this.addArrayObject,
                // showCloseButton: false,
                // showBackButton: true,
                // backButtonText: 'Back',
            }, drawerLevel + 1);
        }
    }

    openAddArrayElement = () => {
        const {
            drawerLevel = 1,
            datalist = [],
            object,
            field,
            fieldObject,
            type,
            min,
            maxValue,
            step,
            elementtype,
            holdingStore,
            isAdmin,
        } = this.props;
        const { appState } = this.props.stores;
        const { drawerHeightSmall } = appState;
        appState.openDrawer('addArrayElement', {
            height: drawerHeightSmall,
            datalist,
            object,
            field,
            fieldObject,
            type,
            min,
            maxValue,
            step,
            elementtype,
            holdingStore,
            isAdmin,
            after: this.addArrayElement,
            // showCloseButton: false,
			// showBackButton: true,
            // backButtonText: 'Back',
        }, drawerLevel + 1);
    }

    removeImage = async (e) => {
        const { object, holdingStore } = this.props;
        const { md5 } = e.target.closest('button').dataset;

        holdingStore.removeImage({
            id: object.id,
            md5,
        });
    }

    removeFile = async (e) => {
        const { object, holdingStore } = this.props;
        const { md5 } = e.target.closest('button').dataset;

        holdingStore.removeFile({
            id: object.id,
            md5,
        });
    }

    removeArrayObject = async (e) => {
        if (!confirm('Are you sure you want to remove this item?')) {
            return false;
        }
        const { md5, uuidv4 } = e.target.closest('button').dataset;
        const { field, object, objectName, holdingStore, isNew, isAdmin } = this.props;
        const id = object.id;
        console.log('removeArrayObject', { md5, uuidv4, field, object, objectName, id, isNew })
        if (!id) {
            holdingStore.removeArrayObjectLocal({ field, id, md5, uuidv4, objectName, isAdmin });
        } else {
            holdingStore.removeArrayObject({ field, id, md5, uuidv4, isAdmin });
        }
    }

    editArrayObject = async (e) => {
        const {
            drawerLevel = 1,
            datalist = [],
            datalistHelp = [],
            datalistValues = [],
            object,
            field,
            fieldObject,
            type,
            min,
            maxValue,
            step,
            elementtype,
            holdingStore,
            drawerSize = 'small',
            drawerName = 'editArrayObject',
            drawerRight = false,
            isAdmin,
        } = this.props;
        const { uuidv4, md5 } = e.target.closest('button').dataset;
        const { appState } = this.props.stores;
        const { drawerHeightSmall, drawerHeightMedium } = appState;
        let drawerHeight = drawerHeightSmall;
        switch (true) {
            case /small/.test(drawerSize):
                drawerHeight = drawerHeightSmall;
                break;
            case /medium/.test(drawerSize):
                drawerHeight = drawerHeightMedium;
                break;
            case /drawerHeight.+/.test(drawerSize):
                drawerHeight = appState[drawerSize];
                break;
            default:
                drawerHeight = drawerHeightSmall;
                break;
        }

        appState.openDrawer(drawerName, {
            height: drawerHeight,
            datalist,
            datalistHelp,
            datalistValues,
            object,
            field,
            fieldObject,
            type,
            min,
            maxValue,
            step,
            elementtype,
            holdingStore,
            md5,
            uuidv4,
            isAdmin,
            // after: this.addArrayObject,
            // showCloseButton: false,
            // showBackButton: true,
            // backButtonText: 'Back',
        }, drawerLevel + 1);
    }

    removeArrayElement = async (e) => {
        if (!confirm('Are you sure you want to remove this item?')) {
            return false;
        }
        // const { key } = e.target.closest('button').dataset;
        // const { field, object, objectName, holdingStore, isNew, isAdmin } = this.props;
        // const id = object.id;
        // // console.log('removeArrayObject', { md5, uuidv4, field, object, objectName, id, isNew })
        // if (!id) {
        //     holdingStore.removeArrayObjectLocal({ field, id, md5, uuidv4, objectName, isAdmin });
        // } else {
        //     holdingStore.removeArrayObject({ field, id, md5, uuidv4, isAdmin });
        // }


        // if (field === 'members') {
        //     this.removeMembers(e);
        // }
    }

    onDragEnd = result => {
        const { field } = this.props;
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        if (field === 'images') {
            this.reorderImages(result.source.index, result.destination.index, 'images');
        } else if (field === 'inlineImages') {
            this.reorderImages(result.source.index, result.destination.index, 'inlineImages');
        } else if (field === 'files') {
            this.reorderFiles(result.source.index, result.destination.index);
        } else if (field === 'links') {
            this.reorderLinks(result.source.index, result.destination.index);
        } else if (field === 'youtubeVideos') {
            this.reorderYoutubeVideos(result.source.index, result.destination.index);
        }
    }

    reorderImages = (startIndex, endIndex, field) => {
        const { holdingStore, object } = this.props;
        const images = [...object[field]];
        if (Array.isArray(images)) {
            const [removed] = images.splice(startIndex, 1);
            images.splice(endIndex, 0, removed);
            if (object.id) {
                holdingStore.updateField(object.id, field, images);
                holdingStore.saveField(object.id, `${field}-overwrite`, images);
            }
        }
    }

    reorderFiles = (startIndex, endIndex) => {
        const { holdingStore, object } = this.props;
        const files = [...object.files];
        if (Array.isArray(files)) {
            const [removed] = files.splice(startIndex, 1);
            files.splice(endIndex, 0, removed);
            if (object.id) {
                holdingStore.updateField(object.id, 'files', files);
                holdingStore.saveField(object.id, 'files-overwrite', files);
            }
        }
    }

    reorderLinks = (startIndex, endIndex) => {
        const { holdingStore, object } = this.props;
        const links = [...object.links];
        if (Array.isArray(links)) {
            const [removed] = links.splice(startIndex, 1);
            links.splice(endIndex, 0, removed);
            if (object.id) {
                holdingStore.updateField(object.id, 'links', links);
                holdingStore.saveField(object.id, 'links-overwrite', links);
            }
        }
    }

    reorderYoutubeVideos = (startIndex, endIndex) => {
        const { holdingStore, object } = this.props;
        const youtubeVideos = [...object.youtubeVideos];
        if (Array.isArray(youtubeVideos)) {
            const [removed] = youtubeVideos.splice(startIndex, 1);
            youtubeVideos.splice(endIndex, 0, removed);
            if (object.id) {
                holdingStore.updateField(object.id, 'youtubeVideos', youtubeVideos);
                holdingStore.saveField(object.id, 'youtubeVideos-overwrite', youtubeVideos);
            }
        }
    }

    searchInput = (e, val) => {
        const { newObject } = this.state;
        const {
            fieldObject,
            holdingStore,
            lookupArray,
            foundListName = 'foundList',
            foundListKeysName = 'foundListKeys',
        } = this.props;
        const search = e ? e.target.value : val;
        if (util.isString(search) && search.length < 1) {
            return false;
        }

        const listName = lookupArray || fieldObject?.lookupArray;
        let foundListKeys = [];
        let foundList = [];
        if (listName && holdingStore[listName]) {
            const list = holdingStore[listName] || [];
            foundListKeys = list[0] ? findAllKeys(list) : [];

            const matchKeys = (item, regExp) => {
                return foundListKeys.some((key) => {
                    // console.log('key', key, item[key], regExp.test(item[key]));
                    if (regExp.test(item[key])) {
                        return true;
                    }
                });
            };
            if (list && search) {
                const regExp = new RegExp(escapeRegExp(search), 'i');
                foundList = list.filter(item => matchKeys(item, regExp)).map(item => {
                    return item;
                });
            }
            // console.log('searchInput', { listName, foundListName, foundListKeysName, list, foundList });
            holdingStore.updateKeyValue(foundListName, foundList);
            holdingStore.updateKeyValue(foundListKeysName, foundListKeys);
        }
    }

    onInput = (e) => {
        const { holdingStore, onInput, callback = () => {}, checkValue = () => {}, object, maxTextLength } = this.props;
        const { id, field, type, elementtype, isnew } = e.target.dataset;
        const { value: rawValue, type: fieldType, min, max } = e.target;

        let value = rawValue;
        // if (fieldType === 'number') {
        //     value = value.replace(',', '.');
        // }
        // console.log('onInput', { fieldType, value });
        this.validateInput(e, value);

        this.searchInput(e);
        if (type === 'datetime-local' && !isValidDate(value)) {
            return false;
        }
        if (type === 'date' && !isValidDate(value)) {
            return false;
        }
        if (onInput) {
            onInput(e, callback);
        } else {
            if (fieldType === 'date') {
                if (min && max) {
                    if (value >= min && value <= max) {
                        this.updateFieldValue({ id: parseInt(id, 10), field, value, type, elementtype, isnew });
                    }
                } else {
                    this.updateFieldValue({ id: parseInt(id, 10), field, value, type, elementtype, isnew });
                }
            } else if (fieldType === 'checkbox') {
                this.updateFieldValue({ id: parseInt(id, 10), field, value: parseInt(value, 10), type, elementtype, isnew });
            } else if (fieldType === 'textarea' && maxTextLength) {
                this.updateFieldValue({ id: parseInt(id, 10), field, value, type, elementtype, isnew });
                if (value.length > maxTextLength) {
                    holdingStore.setFeedbackError(field, `Too many characters, max is ${maxTextLength} you have ${value.length}`);
                } else {
                    holdingStore.setFeedbackError(field, '');

                }
            } else if (fieldType === 'text' && maxTextLength) {
                this.updateFieldValue({ id: parseInt(id, 10), field, value, type, elementtype, isnew });
                if (value.length > maxTextLength) {
                    holdingStore.setFeedbackError(field, `Too many characters, max is ${maxTextLength} you have ${value.length}`);
                } else {
                    holdingStore.setFeedbackError(field, '');

                }
            }
            else {
                this.updateFieldValue({ id: parseInt(id, 10), field, value, type, elementtype, isnew });
            }
            callback(value);
        }
        checkValue(object, holdingStore);
    }

    onSelectInput = ({ value, label, id, field, type, elementtype, isnew }) => {
        const { holdingStore, onInput, callback = () => {}, checkValue = () => {}, object } = this.props;
        this.updateFieldValue({ id: parseInt(id, 10), field, value, type, elementtype, isnew });
        callback(value);
    }

    onMarkdownEditorChange = ({ value, label, id, field, type, elementtype, isnew }) => {
        // console.log('handleEditorChange', { value });
        if (value === '') {
            if (!confirm('Are you sure you want to remove the text?')) {
                return false;
            }
        }
        const { holdingStore, onInput, callback = () => {}, checkValue = () => {}, object } = this.props;
        this.updateFieldValue({ id: parseInt(id, 10), field, value, type, elementtype, isnew });
        callback(value);
    }

    validateInput = (e, value) => {
        const { mustBeNumberText, mustBeNumberBetweenText } = this.props;
        const { validationMessage = {} } = this.state;
        const { field, type } = e.target.dataset;
        const { type: fieldType, min, max } = e.target;
        if (type === 'number') {
            if (!isNumber(value)) {
                validationMessage[field] = mustBeNumberText;
                this.setState({ validationMessage });
                return false;
            }
            const numericValue = Number(value);
            if (min || max) {
                const numericMin = min !== undefined ? Number(min) : -Infinity;
                const numericMax = max !== undefined ? Number(max) : Infinity;
                const isOutsideRange = numericValue < numericMin || numericValue > numericMax;
                validationMessage[field] = isOutsideRange
                    ? `${mustBeNumberBetweenText} ${numericMin} - ${numericMax}`
                    : null;
                this.setState({ validationMessage });
                return !isOutsideRange;
            }
        }
        delete validationMessage[field];
        this.setState({ validationMessage });
    }

    // Function to handle the search input in the org name field
    // passes search to api, then display results in dropdown inner html
    handleSearchInput = async (event) => {
        const {
            object,
            objectName,
            holdingStore,
        } = this.props;
        holdingStore.updateObjectKeyValue(objectName, 'orgName',  event.target.value);
        holdingStore.saveField(object.id, 'orgName', event.target.value);
        const query = event.target.value;
        if (query.length > 0) {
            try {
                const response = await util.fetchApi(
                    `/api/submittedApplications/get-enin-quick-search/${query}`,
                    { method: 'GET' }
                );
                const data = response.data; // Assuming response is already the parsed JSON array
                const dropdown = document.getElementById('dropdown');
                dropdown.innerHTML = ''; // Clear previous results
                const item = document.createElement('div');
                const defaultResult = {};
                defaultResult.search_label_aux = 'n/a';
                defaultResult.search_label = event.target.value;
                item.className = 'dropdown-item border p-2';
                item.textContent = `${event.target.value} - n/a`;
                item.onclick = () => this.selectResult(defaultResult);
                dropdown.appendChild(item);
                data.forEach(result => {
                    const item = document.createElement('div');
                    item.className = 'dropdown-item border p-2';
                    item.textContent = `${result.search_label} - ${result.search_label_aux.replace('NO', '')}`; // Adjust based on API response structure
                    item.onclick = () => this.selectResult(result);
                    dropdown.appendChild(item);
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {
            document.getElementById('dropdown').innerHTML = ''; // Clear dropdown if input is empty
        }
    }
    // updates the input field with the selected result from external api call
    selectResult = (result) => {
        const {
            object,
            field,
            objectName,
            holdingStore,
        } = this.props;
        // Find the input field element
        const orgNrInputField = document.getElementById('orgNumberInput');
        const orgNameInputField = document.getElementById('orgNameInput');

        // Update the input field value with the selected result
        orgNrInputField.value = result.search_label_aux.replace(/NO/g, '');
        orgNameInputField.value = result.search_label;
        holdingStore.updateObjectKeyValue(objectName, field,  result.search_label);
        if (object.id) {
            holdingStore.saveField(object.id, field, result.search_label);
        }
        holdingStore.updateObjectKeyValue(objectName, 'orgNumber',  result.search_label_aux.replace(/NO/g, ''));
        if (object.id) {
            holdingStore.saveField(object.id, 'orgNumber', result.search_label_aux.replace(/NO/g, ''));
        }
        document.getElementById('dropdown').innerHTML = '';
    }

    onPhoneInput = async (cellphone) => {
        const {
            field,
            object = {},
            objectName,
            holdingStore,
            extraDataset = {},
        } = this.props;
        const { id } = object;
        const isnew = extraDataset['data-isnew'];
        this.searchInput(undefined, cellphone);

        if (isnew) {
            holdingStore.updateObjectKeyValue(objectName, field, cellphone);
        } else {
            // console.log('updateFieldValue.updateField', id, field, cellphone);
            holdingStore.updateObjectKeyValue(objectName, field, cellphone);
            holdingStore.updateField(id, field, cellphone);

            clearTimeout(this.updateTimer[field]);
            this.updateTimer[field] = setTimeout(async () => {
                this.doUpdateField(id, field, cellphone, cellphone);
            }, 700);
        }
        // this.setState({ cellphone });
        // if (cellphone && isValidPhoneNumber(cellphone)) {
        //     // this.sendLoginCode(cellphone);
        //     this.setState({ cellphoneIsOk: true });
        // }
    }

    updateFieldValue = async ({ id, field, value, datatype, elementtype, type, isnew = false, skipTimer = false }) => {
        const { holdingStore, object, objectName } = this.props;
        this.setState({ updateInProgress: true });
        let newValue = value;
        let localObjectValue = value;
        if (field === 'images') {
            if (Array.isArray(object.images) && Array.isArray(value)) {
                localObjectValue = [...object.images, ...value];
            } else {
                localObjectValue = [...object.images];
            }
        }
        if (datatype === 'array' && !Array.isArray(value)) {
            if (elementtype === 'number') {
                newValue = value.split(',').map(e => parseFloat(e) || e);
            } else  {
                newValue = value.split(',');
            }
            // console.log('updateFieldValue', id, field, value, datatype, elementtype);
        }
        if (isnew) {
            holdingStore.updateObjectKeyValue(objectName, field, value);
            this.setState({ updateInProgress: false });
        } else {
            // console.log('updateFieldValue.updateField', id, field, localObjectValue);
            holdingStore.updateObjectKeyValue(objectName, field, localObjectValue);
            holdingStore.updateField(id, field, localObjectValue);
            if (skipTimer) {
                await this.doUpdateField(id, field, newValue, localObjectValue);
            } else {
                clearTimeout(this.updateTimer[field]);
                this.updateTimer[field] = setTimeout(async () => {
                    this.doUpdateField(id, field, newValue, localObjectValue);
                }, 700);
            }
        }
    }

    async doUpdateField(id, field, value, localValue) {
        const { holdingStore, isAdmin, objectName } = this.props;
        if (id && field) {
            if (isAdmin) {
                await holdingStore.adminUpdate(id, { [field]: value }, objectName);
            } else {
                // console.log('doAdminUpdate !isAdmin', { id: idAsInt, name, value });
                await holdingStore.saveField(id, field, value);
            }
            // console.log('doUpdateField.saveField', id, field, value);
            // await holdingStore.saveField(id, field, value);
        }
        this.setState({ updateInProgress: false });
    }

    addTag = e => {
        const { tagInput = '' } = this.state;
        const { holdingStore, objectName, isnew, field, extraDataset, foundListName = 'foundList' } = this.props;
        const value = {
            name: tagInput.toLowerCase().trim(),
            date: new Date(),
        };
        value.md5 = md5(JSON.stringify(value)).toString();

        const object = holdingStore[objectName];
        const tagExists = object[field] && object[field].find(tag => tag.name === value.name);
        if (!tagExists) {
            const localValue = object[field] ? [...object[field]] : [];
            if (extraDataset && extraDataset['data-isnew']) {
                holdingStore.updateObjectKeyValue(objectName, field, [...localValue, value]);
            } else {
                holdingStore.saveField(object.id, field, value);
                holdingStore.updateField(object.id, field, [...localValue, value]);
            }
        }
        this.setState({
            tagInput: '',
            selectedTag: '',
        });
        holdingStore.updateKeyValue(foundListName, []);
    }

    removeTag = e => {
        const { md5 } = e.target.closest('button').dataset;
        const { field, object, objectName, holdingStore, isNew, isAdmin } = this.props;
        console.log({ md5, object: object.tags });

        const id = object.id;
        // console.log('removeArrayObject', { md5, uuidv4, field, object, objectName, id, isNew })
        if (!id) {
            holdingStore.removeArrayObjectLocal({ field: 'tags', id, md5, objectName, isAdmin });
        } else {
            holdingStore.removeArrayObject({ field: 'tags', id, md5, isAdmin });
        }
    }

    chooseTag = e => {
        const { holdingStore, objectName, isnew, field, extraDataset, foundListName = 'foundList' } = this.props;
        const { title } = e.target.closest('button').dataset;
        const value = {
            name: title.toLowerCase().trim(),
            date: new Date(),
        };
        value.md5 = md5(JSON.stringify(value)).toString();

        const object = holdingStore[objectName];
        const tagExists = object[field] && object[field].find(tag => tag.name === value.name);
        if (!tagExists) {
            const localValue = object[field] ? [...object[field]] : [];
            if (extraDataset && extraDataset['data-isnew']) {
                holdingStore.updateObjectKeyValue(objectName, field, [...localValue, value]);
            } else {
                holdingStore.saveField(object.id, field, value);
                holdingStore.updateField(object.id, field, [...localValue, value]);
            }
        }
        this.setState({ tagInput: '' });
        holdingStore.updateKeyValue(foundListName, []);
    }

    addTagByName = title => {
        const { holdingStore, objectName, isnew, field, extraDataset, foundListName = 'foundList' } = this.props;
        const value = {
            name: title.toLowerCase().trim(),
            date: new Date(),
        };
        value.md5 = md5(JSON.stringify(value)).toString();

        const object = holdingStore[objectName];
        const tagExists = object[field] && object[field].find(tag => tag.name === value.name);
        if (!tagExists) {
            const localValue = object[field] ? [...object[field]] : [];
            if (extraDataset && extraDataset['data-isnew']) {
                holdingStore.updateObjectKeyValue(objectName, field, [...localValue, value]);
            } else {
                holdingStore.saveField(object.id, field, value);
                holdingStore.updateField(object.id, field, [...localValue, value]);
            }
            this.setState({ tagInput: '' });
            holdingStore.updateKeyValue(foundListName, []);
        }
    }

    checkForKeyDown = (e) => {
        this.searchInput(e);
        const { selectedTag } = this.state;
        switch(e.key) {
            // case 'Escape':
            //     e.stopPropagation();
            //     this.setState({ tagInput: '' });
            //     break;
            case 'Enter':
                if (selectedTag) {
                    this.addTagByName(selectedTag);
                } else {
                    this.addTag(e);
                }
                break;
            case 'ArrowDown':
            case 'ArrowRight':
                this.selectNextTag(e);
                break;
            case 'ArrowUp':
            case 'ArrowLeft':
                this.selectPreviousTag(e);
                break;
            default:
                break;
        }
    }

    selectNextTag = (e) => {
        const { holdingStore, foundListName = 'foundList' } = this.props;
        const { foundList } = holdingStore;
        const { selectedTag } = this.state;
        const idx = foundList.findIndex(tag => tag.name === selectedTag);
        if (idx < foundList.length - 1) {
            this.setState({
                selectedTag: foundList[idx + 1].name,
            });
        }
    }

    selectPreviousTag = (e) => {
        const { holdingStore, foundListName = 'foundList' } = this.props;
        const { foundList } = holdingStore;
        const { selectedTag } = this.state;
        const idx = foundList.findIndex(tag => tag.name === selectedTag);
        if (idx > 0) {
            this.setState({
                selectedTag: foundList[idx - 1].name,
            });
        }
    }

    showImage = e => {
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
		const { src } = e.target.closest('img');
		const { largesrc, md5 } = e.target.closest('img').dataset;
        const { drawerLevel = 1 } = this.props;
        const { drawerHeightLarge } = appState;
        appState.openDrawer('imageFullscreen', {
            md5,
            src:  largesrc || src,
            height: drawerHeightLarge,
            showCloseButton: true,
			// showBackButton: true,
            // backButtonText: 'Back',
            imageDetail: this.imageDetail,
        }, drawerLevel + 1);
    }

    imageDetail = (md5) => {
        const {
            holdingStore,
            objectName,
            object,
        } = this.props;

        const { images = [] } = object;
        // console.log({ object, images, md5 })
        const image = images.find(img => img.md5 === md5);

        if (!image) {
            return '';
        }

        return (<>
            <div class='px-3 py-2 d-flex flex-column'>
                <div class='d-flex flex-row justify-content-between'>
                    {image.format && <div class='mx-1'>
                        <span class='font-weight-lighter'><Text id='usage.viewimage-format'>Format:</Text></span> {image.format}
                    </div>}
                    <div class='mx-1'>
                        <span class='font-weight-lighter'><Text id='usage.viewimage-name'>Name:</Text></span> {image.name}
                    </div>
                    <div class='mx-1'>
                        <span class='font-weight-lighter'><Text id='usage.viewimage-orgsize'>Org size:</Text></span> {image.width} x {image.height} px
                    </div>
                    <div class='mx-1'>
                        <span class='font-weight-lighter'><Text id='usage.viewimage-colorspace'>Colorspace:</Text></span> {image.space}
                    </div>
                    <div class='mx-1'>
                        <span class='font-weight-lighter'><Text id='usage.viewimage-filesize'>Filesize:</Text></span> {util.formatBytes(image.bytes)}
                    </div>
                </div>
            </div>
        </>);
    }

    checkForValue = () => {
        const { loadCallback, field, object } = this.props;
        if (!loadCallback) {
            return false;
        }

        if (!object[field]) {
            setTimeout(() => {
                this.checkForValue();
            }, 1000);
        } else {
            loadCallback();
        }
    }

    before = () => {
        const { before } = this.props;
        if (before) {
            before();
        }
    }

    setUsefulValue = (e) => {
        const { holdingStore, field, object, objectName } = this.props;
        const { value } = e.target.closest('button').dataset;
        holdingStore.updateObjectKeyValue(objectName, field, value);
        holdingStore.updateField(object.id, field, value);
        // Trigger onInput event on inputRef
        this.inputRef.value = value;
        this.inputRef.dispatchEvent(new Event('input', { bubbles: true }));
    }

    componentDidMount() {
        // console.log('componentDidMount')
        this.checkForValue();
        this.before();
    }

    // setLoaded = () => {
    //     this.setState({ loaded: true });
    // }

    // componentWillReceiveProps(nextProps) {
    //     const { loaded } = this.state;
    //     const { object, field } = this.props;
    //     const { field: nextField } = nextProps;

    //     if (!loaded) {
    //         this.setLoaded();
    //         console.log('componentWillReceiveProps', { field, val: object[field] });
    //         this.checkForValue();
    //         this.before();
    //     }
    // }

    render() {
        const {
            object,
            dog,
            type,
            datalist = [],
            usefulValues = [],
            min,
            max,
            step,
            pattern,
            inputmode,
            elementtype,
            field,
            title,
            icon,
            help,
            placeholder = '',
            showDanger = false,
            showWarning = false,
            showFieldInfo,
            viewHtml = true,
            maxLength,
            maxTextLength,
            updateField,
            rightValue,
            displayArrayValue,
            displayArrayObject,
            holdingStore = {},
            extraDataset = {},
            width = '115px',
            height = '115px',
            styles = '',
            lookupArray,
            classNames = 'form-control-plaintext ',
            updateInProgress: updateInProgressProp,
            inputValue,
            onFocus = () => {},
            onBlur = () => {},
            skipSurroundingDivs = false,
            onKeyDown = () => {},
            isTable = false,
            objectName,
            useFirstValueAsBackgroundColorForOption = false,
            checkValue = () => {},
            addToImages,
            toggleFieldFunction,
            toggleFieldValues = {},
        } = this.props;
        const {
            newObject,
            tagInput,
            updateInProgress,
            selectedTag,
            validationMessage = {},
            imageIsLoading = {},
            filter = '',
        } = this.state;
        const {
            saved,
            feedback = {},
            feedbackError = {},
            feedbackButtonFunc = {},
            feedbackButtonTitle = {},
            feedbackButtonProgress = {},
            feedbackButtonDone = {},
            feedbackButtonInfo = {},
        } = holdingStore;
        let value = inputValue || util.getNestedValue(object, field);
        const { userStore, appState } = this.props.stores;
        const { user } = userStore;
        const {
            opts = {},
            imageDirection = 'horizontal',
        } = appState;
        const { loginCountries = ['NO','SE','DK','FI','DE','FR','IT','AT'] } = opts;

        const darkmode = util.getNestedValue(user, 'settings.darkmode');

        let maxValue = max;
        if (type === 'date') {
            if (value && isValidDate(object[field])) {
                value = util.isoDate(object[field], false, false, true);
            }
            if (max === 'today') {
                maxValue = util.isoDate(undefined, false, false, true);
            }
        }
        if (type === 'datetime-local') {
            if (value) {
                value = util.isoDate(object[field], true);
            }
        }
        // if (type === 'time') {
        //     if (value) {
        //         value = util.isoTime(object[field]);
        //     }
        // }
        let displayValue;
        let insertValue;
        if (field === 'duration' || field === 'rest') {
            displayValue = util.secToHms(value, true);
        }
        if (field === 'weather' && util.checkNested(object, 'yr', '0', 'instant', 'details')) {
            const yr = toJS(object.yr[0].instant.details);
            const yrDate = util.isoDate(object.yr[0].date, false, false, true);
            const objectDate = util.isoDate(object.date, false, false, true);
            // air_pressure_at_sea_level, air_temperature, cloud_area_fraction, relative_humidity, wind_from_direction, wind_speed
            if (yrDate === objectDate) {
                displayValue = `Yr.no: 🌡️ ${yr.air_temperature}°C, 💨 ${yr.wind_speed}m/s 🧭 ${localUtil.windDirection(yr.wind_from_direction)}, ☁️ ${yr.cloud_area_fraction}%, ${yr.air_pressure_at_sea_level}mb, 💦 ${yr.relative_humidity}%`;
                insertValue = displayValue;
            }
        }
        if (field === 'temperature' && util.checkNested(object, 'yr', '0', 'instant', 'details')) {
            const yr = toJS(object.yr[0].instant.details);
            const yrDate = util.isoDate(object.yr[0].date, false, false, true);
            const objectDate = util.isoDate(object.date, false, false, true);
            // air_pressure_at_sea_level, air_temperature, cloud_area_fraction, relative_humidity, wind_from_direction, wind_speed
            if (yrDate === objectDate) {
                displayValue = `Yr.no: 🌡️ ${yr.air_temperature}°C`;
                insertValue = util.asNumber(yr.air_temperature);
            }
        }
        if (type === 'file') {
            return (
                <div class='inner'>
                    {/* <div class='col-12 mt-5'>
                        <span class='header2'><Text id='object.add-files'>Add files</Text></span>
                    </div> */}
                    <div class='d-flex flex-row justify-content-start'>
                        <small class='font-weight-light text-muted'>{placeholder}</small>
                    </div>
                    <div class='overflow-auto'>
                        <div class='d-flex flex-row flex-wrap'>

                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId={`droppable-${field}`} direction='vertical'>
                                    {(provided, snapshot) => (
                                        <div
                                            class='d-flex flex-column flex-wrap w-100 mr-2'
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                        >
                                            {util.isArray(object[field]) && object[field].map((file, index) => (
                                                <Draggable
                                                    key={`file-${file.md5}-${index}`}
                                                    draggableId={`${file.md5}-${index}`}
                                                    index={index}
                                                >
                                                    {(provided, snapshot) => {
                                                        // console.log(img)
                                                        return (
                                                            <div
                                                                class='my-1 mx-1'
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, '99%', '80px')}
                                                            >
                                                                <div
                                                                    class='d-flex flex-column align-items-start justify-content-start border overflow-hidden position-relative mr-2 pt-1'
                                                                    style={`border-radius: 10px; width: 100%; height: 80px; overflow-wrap: break-word;`}
                                                                >
                                                                    <small class='px-2 w-100 font-weight-normal'>
                                                                        <i class={`fa-duotone ${localUtil.resolveFontawesomeFiletype(file.ext)}`} />
                                                                        <ClipboardCopy label={file.name} text={`[${file.name}](${file.s3Link})`} />
                                                                    </small>
                                                                    {file.s3LinkStream ? <>
                                                                        <small class='px-2 w-100 font-weight-lighter my-1'>
                                                                            <i class='fa-duotone fa-circle-info text-danger mr-1' /> <Text id='input.stream-no-download'>Is streaming, no download available</Text>
                                                                        </small>
                                                                    </> : <>
                                                                        <small class='px-2 w-100 font-weight-lighter my-1'>
                                                                            <a href={file.s3Link} target='_blank' rel='noopener noreferrer' class='text-decoration-none'>
                                                                                <i class='fas fa-link' /> <Text id='input.download-file'>Download file</Text>
                                                                            </a>
                                                                        </small>
                                                                    </>}
                                                                    <small class='px-2 w-100 font-weight-normal'>
                                                                        {util.formatDate(file.createdDate)} / {localUtil.formatFileSize(file.size)}
                                                                    </small>

                                                                    <button
                                                                        type='button'
                                                                        class='btn btn-sm btn-danger position-absolute p-0'
                                                                        onClick={this.removeArrayObject}
                                                                        data-md5={file.md5}
                                                                        style='
                                                                            top: 5px;
                                                                            right: 5px;
                                                                            width: 20px;
                                                                            height: 20px;
                                                                            z-index: 10000;
                                                                            border-radius: 50%;
                                                                            filter: opacity(0.7);
                                                                            font-size: 0.7em;
                                                                        '
                                                                    >
                                                                        <i class='fas fa-times' />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        );
                                                    }}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>

                            <Files
                                key={`file-input-${field}`}
                                object={object}
                                updateField={this.addFiles}
                                before={this.beforeFilesUploaded}
                                after={this.afterFilesUploaded}
                                autoOpen={false}
                                stores={this.props.stores}
                                text={<Text id='input.add-files'>Add Files</Text>}
                                data-length={object[field] ? object[field].length : 0}
                                field={field}
                            />
                        </div>
                    </div>
                    {help && <small id={`${field}Help`} class='form-text text-muted px-2 mt-0 mb-3'>{help}</small>}
                    {toggleFieldFunction ? <>
                        <InputToggle {...this.props} />
                    </> : null}
                </div>
            );
        }
        if (type === 'image') {
            return (<>
                <div class='inner py-2'>
                    {/* <div class='col-12 mt-5'>
                        <span class='header2'><Text id='object.add-images'>Add images</Text></span>
                    </div> */}
                    <div class='d-flex flex-row justify-content-between'>
                        <small class='font-weight-light text-muted'>{placeholder}</small>
                        <span style='font-size: 1.1em;'>
                            {imageDirection === 'horizontal' ? <>
                                <i class='fa-duotone fa-list' onClick={() => appState.setImageDirection('vertical')}></i>
                            </> : <>
                                <i class='fa-duotone fa-grid' onClick={() => appState.setImageDirection('horizontal')}></i>
                            </>}
                        </span>
                    </div>
                    <div class='overflow-auto mt-2'>
                        <div class='d-flex flex-column flex-wrap '>

                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId={`droppable-${field}`} direction={imageDirection}>
                                    {(provided, snapshot) => (
                                        <div
                                            class={`d-flex flex-${imageDirection === 'horizontal' ? 'row flex-wrap' : 'column w-100 mr-2 overflow-hidden'}`}
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                        >
                                            {util.isArray(object[field]) && object[field].map((img, index) => (
                                                <Draggable key={`${img.md5}-${index}`} draggableId={`${img.md5}-${index}`} index={index}>
                                                    {(provided, snapshot) => {
                                                        return (
                                                            <div
                                                                class={`my-1 mx-1 d-flex flex-${imageDirection === 'horizontal' ? 'column' : 'row w-100'} overflow-hidden`}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, width, imageDirection === 'horizontal' ? `calc(${height} + 30px)` : '50px')}
                                                            >
                                                                <div class={`d-flex flex-${imageDirection === 'horizontal' ? 'column' : 'row w-100'} position-relative`}>
                                                                    <div
                                                                        class='d-flex align-items-center justify-content-center border overflow-hidden position-relative mr-2'
                                                                        style={`border-radius: 10px;
                                                                            ${imageDirection === 'horizontal' ? `width: ${width}; height: ${height};` : `width: 30px; height: 30px; min-width: 30px; min-height: 30px;`};
                                                                        `}
                                                                    >
                                                                        <img
                                                                            src={img.s3SmallLink}
                                                                            // alt={`May be an image of: ${localUtil.imageKeywords(img).join(', ')}`}
                                                                            class='img-fluid'
                                                                            onError={this.handleImageErrored}
                                                                            onLoad={this.handleImageLoaded}
                                                                            data-largesrc={img.s3XXLargeLink}
                                                                            data-md5={img.md5}
                                                                            onClick={this.showImage}
                                                                        />
                                                                        {imageIsLoading[img.md5] === 1 && <>
                                                                            <div
                                                                                class='position-absolute w-100 h-100 d-flex align-items-center justify-content-center'
                                                                                style='background-color: rgba(0,0,0,0.8);'
                                                                            >
                                                                                <div class='spinner-border text-primary' role='status'>
                                                                                    <span class='sr-only'>Loading...</span>
                                                                                </div>
                                                                            </div>
                                                                        </>}
                                                                    </div>
                                                                    <button
                                                                        type='button'
                                                                        class='btn btn-sm btn-danger position-absolute p-0'
                                                                        onClick={this.removeArrayObject}
                                                                        data-md5={img.md5}
                                                                        style='
                                                                            top: 5px;
                                                                            right: 5px;
                                                                            width: 20px;
                                                                            height: 20px;
                                                                            z-index: 10000;
                                                                            border-radius: 50%;
                                                                            filter: opacity(0.7);
                                                                            font-size: 0.7em;
                                                                        '
                                                                    >
                                                                        <i class='fas fa-times' />
                                                                    </button>
                                                                    <div class={`d-flex flex-column flex-fill ${imageDirection === 'vertical' ? 'pr-4' : ''}`} style='line-height: 0.8rem; font-size: 0.7rem;'>
                                                                        <div class='d-flex flex-row w-100  justify-content-between overflow-hidden'>
                                                                            <ClipboardCopy label={img.name} text={`![${img.name}](${img.s3XLargeLink})`} />

                                                                            {imageDirection === 'vertical' && <>
                                                                                {img.exif?.exif?.DateTimeOriginal && <span>
                                                                                    <Text id='input.image-capture-date'>Captured</Text>: {util.formatDate(img.exif.exif.DateTimeOriginal)}
                                                                                </span>}
                                                                            </>}
                                                                        </div>
                                                                        <div class='d-flex flex-row justify-content-between text-muted overflow-hidden'>
                                                                            {/* <span>{img.width}x{img.height}px</span> */}
                                                                            {imageDirection === 'vertical' && <>
                                                                                {img.exif?.image?.Model && <span>
                                                                                    {img.exif.image.Model}
                                                                                    {/* {img.exif?.exif?.LensModel && <span>
                                                                                        &nbsp;/ {img.exif.exif.LensModel}
                                                                                    </span>} */}
                                                                                </span>}
                                                                                {img.exif?.exif?.FocalLength && <span>
                                                                                    {img.exif.exif.FocalLength}mm
                                                                                    {img.exif?.exif?.FNumber && <>
                                                                                        &nbsp;f/{img.exif.exif.FNumber}
                                                                                    </>}
                                                                                    {img.exif?.exif?.ExposureTime && <>
                                                                                        &nbsp;1/{Math.round(1 / img.exif.exif.ExposureTime)}s
                                                                                    </>}
                                                                                </span>}
                                                                                {img.exif?.image?.Artist && <span>
                                                                                    &copy; {img.exif.image.Artist}
                                                                                </span>}
                                                                            </>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            <Images
                                object={object}
                                updateField={this.addImages}
                                before={this.beforeImagesUploaded}
                                after={this.afterImagesUploaded}
                                autoOpen={false}
                                stores={this.props.stores}
                                text={<Text id='input.add-images'>Add Images</Text>}
                                data-length={object[field] ? object[field].length : 0}
                                field={field}
                                addToImages={addToImages}
                            />

                        </div>
                    </div>
                    {help && <small id={`${field}Help`} class='form-text text-muted px-2 mt-0 mb-3'>{help}</small>}
                </div>
                {toggleFieldFunction ? <>
                    <InputToggle {...this.props} />
                </> : null}
            </>);
        }
        if (type === 'datalist') {
            return (
                <div class={`form-group  my-0 py-1  ${saved[`${field}.${object.id}`] ? 'is-valid' : ''}`}>
                    <label for={`${extraDataset && extraDataset['data-id'] ? extraDataset['data-id'] : ''}${field}Input`}>{title}</label>
                    <input
                        id={`${extraDataset && extraDataset['data-id'] ? extraDataset['data-id'] : ''}${field}Input`}
                        list={`list-${field}`}
                        class={`inputForm form-control ${saved[`${field}.${object.id}`] ? 'is-valid' : ''}`}
                        aria-describedby={`${field}Help`}
                        name={field}
                        value={value}
                        data-id={object.id}
                        data-field={field}
                        {...extraDataset}
                        onInput={this.onInput}
                        autocomplete={'off'}
                    />
                    <datalist id={`list-${field}`} >
                        {datalist.map(el => (
                            <option value={el[0]}>{el[1]}</option>
                        ))}
                    </datalist>
                    {saved[`${field}.${object.id}`] ?
                        <div class={`valid-feedback`} style='display: block;'><Text id='input.changes-saved'>Changes saved</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted px-2 mt-0 mb-3'>{help}</small>}
                </div>
            );
        }
        if (type === 'duration') {
            const hh = Math.floor(object[field] / 3600);
            const mi = Math.floor(object[field] % 3600 / 60);
            const ss = Math.floor(object[field] % 3600 % 60);

            return (
                <div class={`form-group my-0 py-1 `}>
                    {/* <span class='float-right'>
                        <strong onClick={this.setValue} data-value={insertValue}>{displayValue}</strong>
                    </span>
                    <label for={`${field}Input`}>{title}</label> */}
                    <div
                        class='row'
                        style='border-radius: 10px !important;'
                    >
                        <div class='col-6'>
                            <div
                                class='input-group rounded rounded-lg position-relative '
                                style='border-radius: 10px !important;'
                            >
                                <div class='input-group-prepend '>
                                    <button
                                        class='btn btn-sm btn-link'
                                        type='button'
                                        data-name={`${field}-hours`}
                                        onClick={this.decDuration}
                                    ><i class='fas fa-minus' /></button>
                                </div>
                                <input
                                    class={`${classNames} text-center`}
                                    id={`${extraDataset && extraDataset['data-id'] ? extraDataset['data-id'] : ''}${field}Input`}
                                    aria-describedby={`${field}Help`}
                                    onInput={this.onInputDuration}
                                    name={`${field}-hours`}
                                    value={hh}
                                    type='number'
                                    min='0'
                                    max='24'
                                    autocomplete={'off'}
                                />
                                <div class='input-group-append '>
                                    <button
                                        class='btn btn-sm btn-link'
                                        type='button'
                                        data-name={`${field}-hours`}
                                        onClick={this.incDuration}
                                    ><i class='fas fa-plus' /></button>
                                </div>
                            </div>
                        </div>
                        <div class='col-6'>
                            <div
                                class='input-group rounded rounded-lg position-relative '
                                style='border-radius: 10px !important;'
                            >
                                <div class='input-group-prepend '>
                                    <button
                                        class='btn btn-sm btn-link'
                                        type='button'
                                        data-name={`${field}-minutes`}
                                        onClick={this.decDuration}
                                    ><i class='fas fa-minus' /></button>
                                </div>
                                <input
                                    class={`${classNames} text-center`}
                                    id={`${extraDataset && extraDataset['data-id'] ? extraDataset['data-id'] : ''}${field}Input`}
                                    aria-describedby={`${field}Help`}
                                    onInput={this.onInputDuration}
                                    name={`${field}-minutes`}
                                    value={mi}
                                    type='number'
                                    min='0'
                                    max='59'
                                    autocomplete={'off'}
                                />
                                <div class='input-group-append '>
                                    <button
                                        class='btn btn-sm btn-link'
                                        type='button'
                                        data-name={`${field}-minutes`}
                                        onClick={this.incDuration}
                                    ><i class='fas fa-plus' /></button>
                                </div>
                            </div>
                        </div>
                        {/* <div class='form-group col-3'>
                            <input class={`form-control`} id={`${field}Input`} aria-describedby={`${field}Help`}
                                onInput={this.onInputDuration}
                                name={`${field}-seconds`}
                                value={ss}
                                type='number'
                                min='0'
                                max='59'
                            />
                        </div> */}
                    </div>
                    {saved[`${field}.${object.id}`] ?
                        <div class={`valid-feedback`} style='display: block;'><Text id='input.changes-saved'>Changes saved</Text></div> :
                        <small id={`${field}Help`} class='form-text text-muted px-2 mt-0 mb-3'>{help}</small>}
                </div>
            );
        }
        if (type === 'toggle') {
            return (<>
                <div class={`form-group mt-0 mb-1 py-2 d-flex justify-content-between align-items-center ${saved[`${field}.${object.id}`] ? 'is-valid' : ''}`}>
                    <div class='flex-grow-1 pl-2 pr-2'>
                        {title}
                        {saved[`${field}.${object.id}`] && <span class={`valid-feedback`} style='display: block;'><Text id='input.changes-saved'>Changes saved</Text></span>}
                    </div>
                    <div class='custom-control custom-switch custom-switch-md d-flex justify-content-end'>
                        <input
                            type='checkbox'
                            class='inputForm custom-control-input'
                            id={`${extraDataset && extraDataset['data-id'] ? extraDataset['data-id'] : ''}${field}Input`}
                            name={field}
                            value={(value === 1 || value === true || value === '1' || value === 'true') ? 0 : 1}
                            data-id={object.id}
                            data-field={field}
                            {...extraDataset}
                            onInput={this.onInput}
                            checked={value == 1 ? 'checked' : ''}
                            autocomplete={'off'}
                        />
                        <label class='custom-control-label pl-2' for={`${extraDataset && extraDataset['data-id'] ? extraDataset['data-id'] : ''}${field}Input`} />
                    </div>
                </div>
                {toggleFieldFunction ? <>
                    <InputToggle {...this.props} />
                </> : null}
                {help && <small id={`${field}Help`} class='form-text text-muted px-2 mt-0 mb-3'>{help}</small>}
                {/* <xmp>{field}: {JSON.stringify(value)}</xmp> */}
            </>);
        }

        if (type === 'selectAdvanced') {
            const currentOption = datalist.find(e => e[0] === parseInt(value, 10));
            return (
                <div class={`form- position-relative my-0 ${skipSurroundingDivs ? '' : 'py-1'} ${saved[`${field}.${object.id}`] ? 'is-valid' : ''}`}>
                    {/* <label for={`${field}Input`}>{title}</label> */}

                    <Select
                        className='basic-single'
                        classNamePrefix='select'
                        styles={{
                            valueContainer: styles => ({
                                ...styles,
                                paddingTop: '10px',
                            }),
                        }}
                        value={currentOption ? { value: currentOption[0], label: currentOption[1] } : null}
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        name={field}
                        onChange={e => this.onSelectInput({
                            value: e.value,
                            label: e.label,
                            id: object.id,
                            field,
                            ...extraDataset
                        })}
                        options={datalist.map(e => ({ value: e[0], label: e[1] }))}
                    />

                    {/* {saved[`${field}.${object.id}`] && <div class={`valid-feedback`} style='display: block;'><Text id='input.changes-saved'>Changes saved</Text></div>} */}
                    {saved[`${field}.${object.id}`] ? <>
                        <div class={`valid-feedback`} style='display: block;'><Text id='input.changes-saved'>Changes saved</Text></div>
                    </> : <>
                        {!skipSurroundingDivs && <>
                            {(value || value === 0) && <span class='form-label-inline text-muted'>{placeholder}</span>}
                        </>}
                    </>}
                    {!skipSurroundingDivs && <>
                        {help && <small id={`${field}Help`} class='form-text text-muted px-2 mt-0 mb-3'>{help}</small>}
                    </>}
                    {toggleFieldFunction ? <>
                        <InputToggle {...this.props} />
                    </> : null}
                </div>
            );
        }

        if (type === 'select') {
            return (
                <div class={`form-group position-relative my-0 ${skipSurroundingDivs ? '' : 'py-1'} ${saved[`${field}.${object.id}`] ? 'is-valid' : ''}`}>
                    {/* <label for={`${field}Input`}>{title}</label> */}

                    <select
                        list={`list-${field}`}
                        class={`inputForm rounded-lg ${classNames} ${saved[`${field}.${object.id}`] ? 'is-valid' : ''}`}
                        style={`
                            ${skipSurroundingDivs ? '' : 'height: 3.3em;'}
                            padding-bottom: 0px;
                            padding-left: .75em;
                            ${styles}
                            ${useFirstValueAsBackgroundColorForOption && value ? `background-color: ${value};` : ''}
                        `}
                        id={`${extraDataset && extraDataset['data-id'] ? extraDataset['data-id'] : ''}${field}Input`}
                        aria-describedby={`${field}Help`}
                        name={field}
                        value={value}
                        data-id={object.id}
                        data-field={field}
                        {...extraDataset}
                        onInput={this.onInput}
                        autoComplete='off'
                        onFocus={onFocus}
                        onBlur={onBlur}
                        autocomplete={'off'}
                    >
                        <option value=''>-- {title} --</option>
                        {datalist.map(el => {
                            // console.log({ el })
                            return (<>
                                <option
                                    value={el[0]}
                                    style={`${useFirstValueAsBackgroundColorForOption ? `background-color: ${el[0]};` : ''}`}
                                >
                                    {el[1]}
                                </option>
                            </>);
                        })}
                    </select>
                    {/* {saved[`${field}.${object.id}`] && <div class={`valid-feedback`} style='display: block;'><Text id='input.changes-saved'>Changes saved</Text></div>} */}
                    {saved[`${field}.${object.id}`] ? <>
                        <div class={`valid-feedback`} style='display: block;'><Text id='input.changes-saved'>Changes saved</Text></div>
                    </> : <>
                        {!skipSurroundingDivs && <>
                            {(value || value === 0) && <span class='form-label-inline text-muted'>{placeholder}</span>}
                        </>}
                    </>}
                    {!skipSurroundingDivs && <>
                        {help && <small id={`${field}Help`} class='form-text text-muted px-2 mt-0 mb-3'>{help}</small>}
                    </>}
                    {toggleFieldFunction ? <>
                        <InputToggle {...this.props} />
                    </> : null}
                </div>
            );
        }

        if (type === 'textarea') {
            return (<>
                <div class={`form-group  my-0 py-1 position-relative ${saved[`${field}.${object.id}`] ? 'is-valid' : ''}`}>
                    {/* <label for={`${field}Input`}>{title}</label> */}
                    <div
                        class='rounded rounded-lg position-relative  px-2 py-1'
                        style=' border-radius: 10px !important; min-height: 100px;'
                    >
                        <textarea
                            class={`inputForm ${classNames} pt-3 pb-1 ${type === 'number' || type === 'date' || type === 'time' ? 'text-center' : ''} ${saved[`${field}.${object.id}`] ? 'is-valid' : ''}`}
                            style={`transition: height 0.7s ease;`}
                            id={`${extraDataset && extraDataset['data-id'] ? extraDataset['data-id'] : ''}${field}Input`}
                            aria-describedby={`${field}Help`}
                            min={min}
                            max={maxValue}
                            maxTextLength={maxTextLength}
                            step={step}
                            rows={5}
                            name={field}
                            value={value}
                            data-id={object.id}
                            data-field={field}
                            data-type={type}
                            data-elementtype={elementtype}
                            {...extraDataset}
                            onInput={this.onInput}
                            onKeyUp={localUtil.resizeTextarea}
                            onFocus={e => {
                                localUtil.resizeTextarea(e);
                                onFocus(e);
                            }}
                            onBlur={e => {
                                localUtil.restoreSizeTextarea(e);
                                onBlur(e);
                            }}
                            onKeyDown={onKeyDown}
                            placeholder={placeholder}
                            autocomplete={'off'}
                        />
                        <div class='position-absolute' style='right: 15px; top: 10px; font-size: 1.1em;'>
                            {object.id && (updateInProgress || updateInProgressProp) && <>
                                <i class='fa-duotone fa-spinner fa-spin' />
                            </>}
                            {saved[`${field}.${object.id}`] && <>
                                <i class='fa-duotone fa-check-circle text-success' />
                            </>}
                        </div>
                        {toggleFieldFunction ? <>
                            <InputToggle {...this.props} />
                        </> : null}
                    </div>
                    {/* {saved[`${field}.${object.id}`] ? <>
                        <div class={`valid-feedback`} style='display: block;'><Text id='input.changes-saved'>Changes saved</Text></div>
                    </> : <> */}
                    {(value || value === 0) && <span class='form-label-inline text-muted'>{placeholder}</span>}
                    {/* </>} */}
                    {!skipSurroundingDivs && <>
                        <div class='d-flex flex-row justify-content-between'>
                            {feedbackError && feedbackError[field] && <>
                                <small class='form-text text-danger px-2 mt-0'><i class='fa-solid fa-triangle-exclamation' /> {feedbackError[field]}</small>
                            </>}
                            {showFieldInfo && <small class='form-text px-2 mt-0 mb-0 flex-fill text-right'>{showFieldInfo({ holdingStore, obj: object, field })}</small>}
                        </div>
                        <div class='d-flex flex-row'>
                            {showWarning && <span class='d-flex flex-row text-warning'><i class='fa-solid fa-triangle-exclamation' style='font-size: 1.2em;' /></span>}
                            {showDanger && <span class='d-flex flex-row text-danger'><i class='fa-solid fa-triangle-exclamation' style='font-size: 1.2em;' /></span>}
                            {help && <small id={`${field}Help`} class='form-text text-muted px-2 mt-0 mb-3'>{help}</small>}
                            {feedback && feedback[field] && <small class='form-text px-2 mt-0 mb-3'>{feedback[field]}</small>}
                        </div>
                    </>}
                </div>
            </>);
        }
        if (type === 'orgLookupSearchBox') {
            return (<>

                <div class={`form-group  my-0 py-1 position-relative ${saved[`${field}.${object.id}`] ? 'is-valid' : ''}`}>
                    {/* <label for={`${field}Input`}>{title}</label> */}
                    <div
                        class='rounded rounded-lg position-relative  px-2 py-1 .custom-control'
                    >
                        <input
                            class="form-control"
                            type="text"
                            id="orgNameInput"
                            placeholder={placeholder}
                            value={value}
                            onInput={this.handleSearchInput}
                        />
                        <div id="dropdown" class="dropdown-content"></div>
                        <div class='position-absolute' style='right: 15px; top: 10px; font-size: 1.1em;'>
                            {object.id && (updateInProgress || updateInProgressProp) && <>
                                <i class='fa-duotone fa-spinner fa-spin' />
                            </>}
                            {saved[`${field}.${object.id}`] && <>
                                <i class='fa-duotone fa-check-circle text-success' />
                            </>}
                        </div>
                        {toggleFieldFunction ? <>
                            <InputToggle {...this.props} />
                        </> : null}
                    </div>
                    {/* {saved[`${field}.${object.id}`] ? <>
                        <div class={`valid-feedback`} style='display: block;'><Text id='input.changes-saved'>Changes saved</Text></div>
                    </> : <> */}
                    {(value || value === 0) && <span class='form-label-inline text-muted'>{placeholder}</span>}
                    {/* </>} */}
                    {!skipSurroundingDivs && <>
                        <div class='d-flex flex-row justify-content-between'>
                            {feedbackError && feedbackError[field] && <>
                                <small class='form-text text-danger px-2 mt-0'><i class='fa-solid fa-triangle-exclamation' /> {feedbackError[field]}</small>
                            </>}
                            {showFieldInfo && <small class='form-text px-2 mt-0 mb-0 flex-fill text-right'>{showFieldInfo({ holdingStore, obj: object, field })}</small>}
                        </div>
                        <div class='d-flex flex-row'>
                            {showWarning && <span class='d-flex flex-row text-warning'><i class='fa-solid fa-triangle-exclamation' style='font-size: 1.2em;' /></span>}
                            {showDanger && <span class='d-flex flex-row text-danger'><i class='fa-solid fa-triangle-exclamation' style='font-size: 1.2em;' /></span>}
                            {help && <small id={`${field}Help`} class='form-text text-muted px-2 mt-0 mb-3'>{help}</small>}
                            {feedback && feedback[field] && <small class='form-text px-2 mt-0 mb-3'>{feedback[field]}</small>}
                        </div>
                    </>}
                </div>
            </>);
        }
        if (type === 'orgNumberSearchResultReciever') {
            return (<>

                <div class={`form-group  my-0 py-1 position-relative ${saved[`${field}.${object.id}`] ? 'is-valid' : ''}`}>
                    {/* <label for={`${field}Input`}>{title}</label> */}
                    <div
                        class='rounded rounded-lg position-relative  px-2 py-1 .custom-control'
                    >
                        <input
                            class="form-control"
                            type="text"
                            id="orgNumberInput"
                            placeholder="OrgNr"
                            value={value}
                        />
                        <div class='position-absolute' style='right: 15px; top: 10px; font-size: 1.1em;'>
                            {object.id && (updateInProgress || updateInProgressProp) && <>
                                <i class='fa-duotone fa-spinner fa-spin' />
                            </>}
                            {saved[`${field}.${object.id}`] && <>
                                <i class='fa-duotone fa-check-circle text-success' />
                            </>}
                        </div>
                        {toggleFieldFunction ? <>
                            <InputToggle {...this.props} />
                        </> : null}
                    </div>
                    {/* {saved[`${field}.${object.id}`] ? <>
                        <div class={`valid-feedback`} style='display: block;'><Text id='input.changes-saved'>Changes saved</Text></div>
                    </> : <> */}
                    {(value || value === 0) && <span class='form-label-inline text-muted'>{placeholder}</span>}
                    {/* </>} */}
                    {!skipSurroundingDivs && <>
                        <div class='d-flex flex-row justify-content-between'>
                            {feedbackError && feedbackError[field] && <>
                                <small class='form-text text-danger px-2 mt-0'><i class='fa-solid fa-triangle-exclamation' /> {feedbackError[field]}</small>
                            </>}
                            {showFieldInfo && <small class='form-text px-2 mt-0 mb-0 flex-fill text-right'>{showFieldInfo({ holdingStore, obj: object, field })}</small>}
                        </div>
                        <div class='d-flex flex-row'>
                            {showWarning && <span class='d-flex flex-row text-warning'><i class='fa-solid fa-triangle-exclamation' style='font-size: 1.2em;' /></span>}
                            {showDanger && <span class='d-flex flex-row text-danger'><i class='fa-solid fa-triangle-exclamation' style='font-size: 1.2em;' /></span>}
                            {help && <small id={`${field}Help`} class='form-text text-muted px-2 mt-0 mb-3'>{help}</small>}
                            {feedback && feedback[field] && <small class='form-text px-2 mt-0 mb-3'>{feedback[field]}</small>}
                        </div>
                    </>}
                </div>
            </>);
        }
        if (type === 'textareaEditor') {
            return (<>
                <div class={`form-group  my-0 py-1 position-relative ${saved[`${field}.${object.id}`] ? 'is-valid' : ''}`}>
                    {/* <label for={`${field}Input`}>{title}</label> */}
                    <div
                        class='rounded rounded-lg position-relative  px-2 py-1'
                        style=' border-radius: 10px !important; min-height: 100px;'
                    >
                        <MdEditor
                            id={`${extraDataset && extraDataset['data-id'] ? extraDataset['data-id'] : ''}${field}Input`}
                            style={{ height: '500px' }}
                            renderHTML={text => Markdown(text, { markupOpts: localUtil.getMarkdownOptions() })}
                            onChange={(content, input) => this.onMarkdownEditorChange({
                                value: content.text,
                                id: object.id,
                                field,
                                ...extraDataset
                            })}
                            value={value || ''}
                            name={field}
                            placeholder={placeholder}
                            view={{ menu: true, md: true, html: viewHtml }}
                        />
                        <div class='position-absolute' style='right: 15px; top: 10px; font-size: 1.1em;'>
                            {object.id && (updateInProgress || updateInProgressProp) && <>
                                <i class='fa-duotone fa-spinner fa-spin' />
                            </>}
                            {saved[`${field}.${object.id}`] && <>
                                <i class='fa-duotone fa-check-circle text-success' />
                            </>}
                        </div>
                    </div>
                    {/* {saved[`${field}.${object.id}`] ? <>
                        <div class={`valid-feedback`} style='display: block;'><Text id='input.changes-saved'>Changes saved</Text></div>
                    </> : <> */}
                    {(value || value === 0) && <span class='form-label-inline text-muted'>{placeholder}</span>}
                    {/* </>} */}
                    {!skipSurroundingDivs && <>
                        <div class='d-flex flex-row justify-content-between'>
                            {feedbackError && feedbackError[field] && <>
                                <small class='form-text text-danger px-2 mt-0'><i class='fa-solid fa-triangle-exclamation' /> {feedbackError[field]}</small>
                            </>}
                            {showFieldInfo && <small class='form-text px-2 mt-0 mb-0 flex-fill text-right'>{showFieldInfo({ holdingStore, obj: object, field })}</small>}
                        </div>
                        <div class='d-flex flex-row'>
                            {showWarning && <span class='d-flex flex-row text-warning'><i class='fa-solid fa-triangle-exclamation' style='font-size: 1.2em;' /></span>}
                            {showDanger && <span class='d-flex flex-row text-danger'><i class='fa-solid fa-triangle-exclamation' style='font-size: 1.2em;' /></span>}
                            {help && <small id={`${field}Help`} class='form-text text-muted px-2 mt-0 mb-3'>{help}</small>}
                            {feedback && feedback[field] && <small class='form-text px-2 mt-0 mb-3'>{feedback[field]}</small>}
                        </div>
                    </>}
                </div>
            </>);
        }

        if (type === 'arrayObject') {
            return (<>
                <div class='d-flex flex-row flex-wrap w-100 py-2'>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId={`droppable-${field}`} direction='horizontal'>
                            {(provided, snapshot) => (
                                <div
                                    class='d-flex flex-row flex-wrap'
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    {util.isArray(object[field]) && object[field].map((file, index) => (
                                        <Draggable key={`arrayobject-${file?.md5}-${index}`} draggableId={`${file?.md5}-${index}`} index={index}>
                                            {(provided, snapshot) => {
                                                // console.log({ file })
                                                return (
                                                    <div
                                                        class='my-2 mr-2'
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, width, height)}
                                                    >
                                                        <div
                                                            class='d-flex align-items-center justify-content-center text-center border overflow-hidden position-relative mr-2'
                                                            style={`border-radius: 10px; width: ${width}; height: ${height}; font-size: 0.8em;`}
                                                        >
                                                            {displayArrayObject ? displayArrayObject(file) : file.title}
                                                            <button
                                                                type='button'
                                                                class='btn btn-sm btn-link position-absolute p-0 text-danger'
                                                                onClick={this.removeArrayObject}
                                                                data-md5={file?.md5}
                                                                data-uuidv4={file?.uuidv4}
                                                                style='
                                                                    top: 2px;
                                                                    right: 2px;
                                                                    width: 20px;
                                                                    height: 20px;
                                                                    z-index: 10000;
                                                                    border-radius: 50%;
                                                                    filter: opacity(0.5);
                                                                    font-size: 0.9em;
                                                                '
                                                            >
                                                                <i class='fas fa-times' />
                                                            </button>
                                                            <button
                                                                type='button'
                                                                class='btn btn-sm btn-link position-absolute p-0 text-primary'
                                                                onClick={this.editArrayObject}
                                                                data-md5={file?.md5}
                                                                data-uuidv4={file?.uuidv4}
                                                                style='
                                                                    top: 2px;
                                                                    left: 2px;
                                                                    width: 20px;
                                                                    height: 20px;
                                                                    z-index: 10000;
                                                                    border-radius: 50%;
                                                                    filter: opacity(0.5);
                                                                    font-size: 0.9em;
                                                                '
                                                            >
                                                                <i class='fas fa-edit' />
                                                            </button>
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <div
                        class='d-flex flex-column align-items-center justify-content-center border overflow-hidden position-relative bg-light my-2 mr-2'
                        style={`
                            border-radius: 10px;
                            width: ${width};
                            height: ${height};
                            border-style: dashed !important;
                            border-color: #6c757d !important;
                        `}
                        data-length={object[field] ? object[field].length : 0}
                        onClick={this.openAddArrayObject}
                    >
                        <i class='fa-regular fa-square-plus text-primary' /><br />
                        <small class='font-weight-light text-center text-muted'>Add {title}</small>
                    </div>
                </div>

                {saved[`${field}.${object.id}`] && <div class={`valid-feedback`} style='display: block;'><Text id='input.changes-saved'>Changes saved</Text></div>}
                {help && <small id={`${field}Help`} class='form-text text-muted px-2 mt-0 mb-3'>{help}</small>}
                {/* <xmp>{JSON.stringify(newObject, null, 4)}</xmp> */}
            </>);
        }

        if (type === 'arrayElement') {
            return (<>
                <div class='d-flex flex-row flex-wrap py-2'>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId={`droppable-${field}`} direction='horizontal'>
                            {(provided, snapshot) => (
                                <div
                                    class='d-flex flex-row flex-wrap w-100 mr-2'
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                    {...provided.droppableProps}
                                >
                                    {util.isArray(object[field]) && object[field].map((file, index) => (
                                        <Draggable key={`arrayobject-${file}-${index}`} draggableId={`${file}-${index}`} index={index}>
                                            {(provided, snapshot) => {
                                                // console.log(img)
                                                return (
                                                    <div
                                                        class='my-2'
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, width, height)}
                                                    >
                                                        <div
                                                            class='d-flex align-items-center justify-content-center text-center border overflow-hidden position-relative mr-2'
                                                            style={`border-radius: 10px; width: ${width}; height: ${height}; font-size: 0.8em;`}
                                                        >
                                                            {displayArrayValue ? displayArrayValue(file) : file}
                                                            <button
                                                                type='button'
                                                                class='btn btn-sm btn-secondary position-absolute p-0'
                                                                onClick={this.removeArrayElement}
                                                                data-key={file}
                                                                style='
                                                                    top: 2px;
                                                                    left: 2px;
                                                                    width: 20px;
                                                                    height: 20px;
                                                                    z-index: 10000;
                                                                    border-radius: 50%;
                                                                    filter: opacity(0.5);
                                                                    font-size: 0.7em;
                                                                '
                                                            >
                                                                <i class='fas fa-times' />
                                                            </button>
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <div
                        class='d-flex flex-column align-items-center justify-content-center border overflow-hidden position-relative bg-light my-2'
                        style={`
                            border-radius: 10px;
                            width: ${width};
                            height: ${height};
                            border-style: dashed !important;
                            border-color: #6c757d !important;
                        `}
                        data-length={object[field] ? object[field].length : 0}
                        onClick={this.openAddArrayElement}
                    >
                        <i class='fa-regular fa-square-plus text-primary' /><br />
                        <small class='font-weight-light text-center text-muted'>Add {title}</small>
                    </div>
                </div>

                {saved[`${field}.${object.id}`] && <div class={`valid-feedback`} style='display: block;'><Text id='input.changes-saved'>Changes saved</Text></div>}
                {help && <small id={`${field}Help`} class='form-text text-muted px-2 mt-0 mb-3'>{help}</small>}
                {/* <xmp>{JSON.stringify(newObject, null, 4)}</xmp> */}
            </>);
        }

        if (type === 'phone') {
            return (<>
                <div class={`form-group my-0 py-2 position-relative ${saved[`${field}.${object.id}`] ? 'is-valid' : ''}`}>
                    <div
                        class='rounded rounded-lg position-relative px-2 py-1'
                        style='border-radius: 10px !important;'
                    >
                        <Localizer>
                            <PhoneInput
                                className={``}
                                numberInputProps={{
                                    className: `inputForm input-plain ${classNames} pt-2 pb-1 ${value ? (isValidPhoneNumber(value) ? 'is-valid' : 'is-invalid') : ''}`,
                                    name: field,
                                }}
                                limitMaxLength={true}
                                addInternationalOption={false}
                                international={false}
                                defaultCountry='NO'
                                countries={loginCountries}
                                placeholder={<Text id='input.phonenumber-help'>Enter phone number</Text>}
                                value={value}
                                onChange={this.onPhoneInput}
                                error={value ? (isValidPhoneNumber(value) ? undefined : 'Invalid phone number') : 'Phone number required'}
                            />
                        </Localizer>
                        <div class='position-absolute' style='right: 15px; top: 10px; font-size: 1.1em;'>
                            {object.id && (updateInProgress || updateInProgressProp) && <>
                                <i class='fa-duotone fa-spinner fa-spin' />
                            </>}
                        </div>
                        {toggleFieldFunction ? <>
                            <InputToggle {...this.props} />
                        </> : null}
                    </div>
                    {saved[`${field}.${object.id}`] ? <>
                        <div class={`valid-feedback`} style='display: block;'><Text id='input.changes-saved'>Changes saved</Text></div>
                    </> : <>
                        {((value || value === 0) || ['date', 'datetime-local'].indexOf(type) > -1) && <span class='form-label-inline text-muted'>{placeholder}</span>}
                    </>}
                    {help && <small id={`${field}Help`} class='form-text text-muted px-2 mt-0 mb-3'>{help}</small>}
                </div>
            </>);
        }

        if (type === 'tags') {
            const {
                foundListName = 'foundList',
            } = this.props;
            return (<>
                <div class={`form-group my-0 py-2 position-relative ${saved[`${field}.${object.id}`] ? 'is-valid' : ''}`}>

                    <div class='mt-3'>
                        {object.tags?.length > 0 && <div class='d-flex flex-wrap'>
                            {object.tags && object.tags.map((tag, idx) => {
                                const tagClass = darkmode ? 'dark' : 'light';
                                return (<>
                                    <span
                                        class={`badge badge-pill badge-${tagClass} mr-1 mt-1`}
                                    >
                                        #{tag.name}
                                        <button
                                            type='button'
                                            class='btn btn-sm btn-danger rounded-pill-right py-0 my-0 ml-2'
                                            onClick={this.removeTag}
                                            data-md5={tag.md5}
                                        >
                                            <i class='fas fa-times' />
                                        </button>
                                    </span>
                                </>);
                            })}
                        </div>}
                        <div class='d-flex flex-row justify-content-end mt-1'>
                            <div class='input-group input-group-sm ' style='width: 40vw !important;'>
                                <div class='input-group-prepend'>
                                    <span class='input-group-text  rounded-pill-left'>#</span>
                                </div>
                                <Localizer>
                                    <input
                                        class={`form-control form-control-sm ${tagInput ? '' : 'rounded-pill-right'}`}
                                        type='text'
                                        value={tagInput}
                                        placeholder={<Text id='input.add-tag'>Add tag</Text>}
                                        onInput={linkstate(this, `tagInput`)}
                                        onKeyDown={this.checkForKeyDown}
                                        autocomplete={'off'}
                                    />
                                </Localizer>
                                {tagInput && <div class='input-group-append'>
                                    <button class='btn btn-sm btn-success rounded-pill-right' type='button' onClick={this.addTag}>
                                        <i class='fa-solid fa-check'></i>
                                    </button>
                                </div>}
                            </div>
                        </div>
                        {foundListName && holdingStore[foundListName]?.length > 0 && <>
                            <div class='d-flex flex-row justify-content-end mt-1'>
                                <div class='input-group input-group-sm ' style='width: 40vw !important;'>
                                    {holdingStore[foundListName].map((item, idx) => {
                                        return(<>
                                            <div class='d-flex flex-row justify-content-between align-items-center'>
                                                <button
                                                    type='button'
                                                    class={`btn btn-sm rounded-pill btn-${selectedTag === item.name ? 'success' : 'outline-secondary'}`}
                                                    data-title={item.name}
                                                    onClick={this.chooseTag}
                                                >
                                                    {item.name}
                                                </button>
                                            </div>
                                        </>);
                                    })}
                                </div>
                            </div>
                        </>}
                    </div>

                    {saved[`${field}.${object.id}`] ? <>
                        <div class={`valid-feedback`} style='display: block;'><Text id='input.changes-saved'>Changes saved</Text></div>
                    </> : <>
                        {((value || value === 0) || ['date', 'datetime-local'].indexOf(type) > -1) && <span class='form-label-inline text-muted'>{placeholder}</span>}
                    </>}
                    {help && <small id={`${field}Help`} class='form-text text-muted px-2 mt-0 mb-3'>{help}</small>}
                </div>
            </>);
        }

        if (!isTable && type === 'date') {
            return (
                <div class={skipSurroundingDivs ? `form-group my-0 position-relative ${saved[`${field}.${object.id}`] ? 'is-valid' : ''}` : `form-group my-0 py-2 position-relative ${saved[`${field}.${object.id}`] ? 'is-valid' : ''}`}>
                    {/* {type === 'date' && <span class='float-right text-muted font-weight-lighter' onClick={this.setDateToNow}>I dag <i class='far fa-check-circle' /></span>}
                    {type === 'time' && <span class='float-right text-muted font-weight-lighter' onClick={this.setTimeToNow}>Nå <i class='far fa-check-circle' /></span>}
                    {rightValue && <span class='float-right text-muted font-weight-lighter' onClick={this.setRightValue}>{rightValue}</span>}

                    <label for={`${field}Input`}>{title}</label>
                    {displayValue && <span class='float-right'>
                    <strong onClick={this.setValue} data-value={insertValue}>{displayValue}</strong>
                    </span>} */}
                    <div
                        class={skipSurroundingDivs ? 'd-flex flex-row rounded rounded-lg position-relative' : 'd-flex flex-row rounded rounded-lg position-relative px-2 py-1'}
                        style={`
                            border-radius: 10px !important;
                        `}
                    >
                        <DatePicker
                            stores={this.props.stores}
                            field={field}
                            object={object}
                            objectName={objectName}
                            value={object[field]}
                            checkValue={checkValue}
                            min={min}
                            max={maxValue}
                            holdingStore={holdingStore}
                            key={`${field}-${object.id}`}
                            skipSurroundingDivs={skipSurroundingDivs}
                        />

                        <div class='position-absolute' style='right: 15px; top: 10px; font-size: 1.1em;'>
                            {object.id && (updateInProgress || updateInProgressProp) && <>
                                <i class='fa-duotone fa-spinner fa-spin' />
                            </>}
                            {saved[`${field}.${object.id}`] && <>
                                <i class='fa-duotone fa-check-circle text-success' />
                            </>}
                        </div>
                        {toggleFieldFunction ? <>
                            <InputToggle {...this.props} />
                        </> : null}
                    </div>
                    {validationMessage[field] && (
                        <div class="d-flex flex-row text-danger">
                            <small>
                                {validationMessage[field]}
                            </small>
                        </div>
                    )}
                    {/* {saved[`${field}.${object.id}`] ? <>
                        <div class={`valid-feedback`} style='display: block;'><Text id='input.changes-saved'>Changes saved</Text></div>
                    </> : <> */}
                    {!skipSurroundingDivs && <>
                        {((value || value === 0) || ['date', 'datetime-local'].indexOf(type) > -1) && <span class='form-label-inline text-muted'>{placeholder}</span>}
                    </>}
                    {!skipSurroundingDivs && <>
                        {showWarning && <span class='d-flex flex-row text-warning'><i class='fa-solid fa-triangle-exclamation' style='font-size: 1.2em;' /></span>}
                        {showDanger && <span class='d-flex flex-row text-danger'><i class='fa-solid fa-triangle-exclamation' style='font-size: 1.2em;' /></span>}
                        {help && <small id={`${field}Help`} class='form-text text-muted px-2 mt-0 mb-3'>{help}</small>}
                        {feedbackError && feedbackError[field] && <small class='form-text text-danger px-2 mt-0 mb-3'>{feedbackError[field]}</small>}
                        {feedback && feedback[field] && <small class='form-text px-2 mt-0 mb-3'>{feedback[field]}</small>}
                        {feedbackButtonFunc && feedbackButtonFunc[field] && <>
                            <button
                                class='btn btn-sm btn-info rounded-pill ml-2'
                                onClick={feedbackButtonFunc[field]}
                            >
                                {feedbackButtonTitle[field]}
                            </button>
                        </>}
                        {showFieldInfo && <small class='form-text px-2 mt-0 mb-3'>{showFieldInfo({ holdingStore, obj: object, field })}</small>}
                    </>}
                </div>
            );
        }

        return (
            <div class={skipSurroundingDivs ? `form-group my-0 position-relative ${saved[`${field}.${object.id}`] ? 'is-valid' : ''}` : `form-group my-0 py-2 position-relative ${saved[`${field}.${object.id}`] ? 'is-valid' : ''}`}>
                {/*

                    {type === 'date' && <span class='float-right text-muted font-weight-lighter' onClick={this.setDateToNow}>I dag <i class='far fa-check-circle' /></span>}
                    {type === 'time' && <span class='float-right text-muted font-weight-lighter' onClick={this.setTimeToNow}>Nå <i class='far fa-check-circle' /></span>}
                    {rightValue && <span class='float-right text-muted font-weight-lighter' onClick={this.setRightValue}>{rightValue}</span>}

                    <label for={`${field}Input`}>{title}</label>
                    {displayValue && <span class='float-right'>
                    <strong onClick={this.setValue} data-value={insertValue}>{displayValue}</strong>
                    </span>}

                    For phone like input on an iPhone and Android use this input to

                        type='tel'
                        inputmode='numeric'
                        pattern='[0-9]*'

                */}
                <div
                    class={skipSurroundingDivs ? 'd-flex flex-row rounded rounded-lg position-relative' : 'd-flex flex-row rounded rounded-lg position-relative px-2 py-1'}
                    style={`
                        border-radius: 10px !important;
                    `}
                >
                    <input
                        ref={c => this.inputRef = c}
                        type={type === 'forceNumber' ? 'number' : ['number', 'text'].includes(type) ? 'text' : type}
                        pattern={pattern}
                        inputmode={inputmode}
                        class={`inputForm input-plain ${classNames} pt-2 pb-1 ${type === 'number' ? 'text-left' : ''} ${type === 'date' || type === 'time' ? 'text-center' : ''} ${saved[`${field}.${object.id}`] ? 'is-valid' : ''} ${validationMessage[field] ? 'is-invalid' : ''}`}
                        style={`
                            padding-top: .55rem !important;
                            ${styles}
                            ${validationMessage[field] ? 'border-bottom-color: #dc3545 !important;' : ''}
                        `}
                        id={`${extraDataset && extraDataset['data-id'] ? extraDataset['data-id'] : ''}${field}Input`}
                        aria-describedby={`${field}Help`}
                        min={min}
                        max={maxValue}
                        maxLength={maxLength}
                        maxTextLength={maxTextLength}
                        step={step}
                        name={field}
                        value={value || ''}
                        data-id={object.id}
                        data-field={field}
                        data-type={type}
                        data-elementtype={elementtype}
                        {...extraDataset}
                        onInput={this.onInput}
                        placeholder={placeholder}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        autocomplete={'off'}
                    />
                    {feedbackButtonFunc && feedbackButtonFunc[field] && <>
                        {feedbackButtonDone[field] ? <>
                            <i class='fa-duotone fa-check-circle text-success' />
                        </> : <>
                            <button
                                disabled={feedbackButtonProgress[field]}
                                class='btn btn-sm btn-info rounded-pill-right'
                                onClick={feedbackButtonFunc[field]}
                            >
                                <nobr>
                                    {feedbackButtonProgress[field] && <>
                                        <i class='fa-duotone fa-spinner fa-spin' />
                                    </>}
                                    {feedbackButtonTitle[field]}
                                    <i class='fa-duotone fa-share-from-square ml-2' />
                                </nobr>
                            </button>
                        </>}
                    </>}
                    <div class='position-absolute' style='right: 15px; top: 10px; font-size: 1.1em;'>
                        {object.id && (updateInProgress || updateInProgressProp) && <>
                            <i class='fa-duotone fa-spinner fa-spin' />
                        </>}
                        {saved[`${field}.${object.id}`] && <>
                            <i class='fa-duotone fa-check-circle text-success' />
                        </>}
                    </div>

                    {toggleFieldFunction ? <>
                        <InputToggle {...this.props} />
                    </> : null}
                </div>
                {(feedbackButtonInfo[field] || showFieldInfo) && <>
                    <div class='d-flex flex-row justify-content-end'>
                        {feedbackButtonInfo[field] ? <>
                            <small class='form-text text-muted px-2 mt-0 mb-3'>{feedbackButtonInfo[field]}</small>
                        </> : <>
                            {showFieldInfo && <small class='form-text px-2 mt-0 mb-0 flex-fill text-right'>{showFieldInfo({ holdingStore, obj: object, field })}</small>}
                        </>}
                    </div>
                </>}
                {validationMessage[field] && (
                    <div class="d-flex flex-row text-danger">
                        <small>
                            {validationMessage[field]}
                        </small>
                    </div>
                )}
                {/* {saved[`${field}.${object.id}`] ? <>
                    <div class={`valid-feedback`} style='display: block;'><Text id='input.changes-saved'>Changes saved</Text></div>
                </> : <> */}
                {!skipSurroundingDivs && <>
                    {((value || value === 0) || ['date', 'datetime-local'].indexOf(type) > -1) && <span class='form-label-inline text-muted'>{placeholder}</span>}
                </>}
                {!skipSurroundingDivs && <>
                    <div class='d-flex flex-row justify-content-between'>
                        {feedbackError && feedbackError[field] && <>
                            <small class='form-text text-danger px-2 mt-0'><i class='fa-solid fa-triangle-exclamation' /> {feedbackError[field]}</small>
                        </>}
                    </div>
                    <div class='d-flex flex-row'>
                        {showWarning && <span class='d-flex flex-row text-warning'><i class='fa-solid fa-triangle-exclamation' style='font-size: 1.2em;' /></span>}
                        {showDanger && <span class='d-flex flex-row text-danger'><i class='fa-solid fa-triangle-exclamation' style='font-size: 1.2em;' /></span>}
                        {feedback && feedback[field] && <small class='form-text px-2 mt-0 mb-0'>{feedback[field]}</small>}
                    </div>
                    <div class='d-flex flex-row'>
                        {help && <small id={`${field}Help`} class='form-text text-muted px-2 mt-0 mb-0'>{help}</small>}
                    </div>
                </>}
                {usefulValues && usefulValues.length > 0 && <>
                    <div class='d-flex flex-row'>
                        {usefulValues.map((usefulValue, idx) => {
                            return (<>
                                <button
                                    type='button'
                                    class='btn btn-sm btn-link py-0'
                                    onClick={this.setUsefulValue}
                                    data-value={usefulValue}
                                >
                                    {usefulValue}
                                </button>
                            </>);
                        })}
                    </div>
                </>}
            </div>
        );
    }
}

export default Input;
// Hepp